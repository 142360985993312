@use '../../../styles/utils' as *;

.newsListingCard {
  cursor: pointer;
  width: 100%;

  h4 {
    margin-bottom: rem(12);
  }
}

.cardImage {
  align-items: center;
  display: flex;
  height: rem(180);
  justify-content: center;
  margin-bottom: rem(14);
  overflow: hidden;
  position: relative;
  width: 100%;

  @include themify($themes) {
    background-color: themed('backgroundColorSecondary');
  }

  @include breakpoint-md {
    height: auto;
    height: rem(180);
    width: auto;
  }

  @include breakpoint-lg {
    height: rem(218);
  }

  img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.listingType {
  margin-bottom: rem(8);
}

.readingTime {
  @include base-smallest-semibold;

  align-items: center;
  color: $color-slate;
  display: flex;

  span {
    height: rem(14);
    margin-right: rem(5);
    width: rem(14);
  }
}
