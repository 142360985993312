@use '../../../styles/utils' as *;

.contactCardContainer {
  background-color: $color-white;
  display: flex;
  flex-direction: column;

  @include breakpoint-md {
    flex-direction: row;
    width: rem(308);
  }
}

.contactCardImage {
  margin-bottom: rem(25);

  @include breakpoint-md {
    flex-direction: row;
    margin-bottom: 0;
    margin-right: rem(25);
    width: rem(75);
  }
}

.contactCardImage img {
  max-width: rem(160);
  width: 100%;
}

.contactCardContentWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.contactCardMetadata p {
  line-height: rem(24);
  margin-bottom: rem(4);
}

.contactCardName {
  font-weight: bold;
}

.contactCardCtas {
  display: flex;
  flex-direction: column;
  margin-top: rem(8);
  max-width: rem(350);
}

.contactCardCtas > span {
  margin-bottom: rem(12);
}

.contactCardCtas > button {
  justify-content: space-between;
  width: auto;
}

.contactCardCtas > span > a {
  justify-content: space-between;
  width: auto;
}

.contactCardCtasLeft {
  align-items: center;
  display: flex;
}

.contactCardCtasIcon span {
  margin: 0 0.375rem 0 0.625rem;
  width: 0.75rem;
}

.contactCardContainer.propertyVariant {
  align-items: center;
  background-color: $color-light-lilac;
  box-sizing: border-box;
  flex-direction: column;
  padding: rem(25);
  width: 100%;

  @include themify($themes) {
    background-color: themed('backgroundColorSecondary');
  }
}

.propertyVariant .contactCardImage {
  margin-bottom: rem(15);
  margin-right: 0;
  width: rem(128);
}

.propertyVariant .contactCardContentWrapper {
  align-items: center;
  width: 100%;
}

.propertyVariant .contactCardMetadata {
  margin-bottom: 15px;
}

.propertyVariant .contactCardPosition {
  display: none;
}

.propertyVariant .contactCardCtas {
  width: 100%;
}

.propertyVariant .contactCardCtas > span:last-child {
  margin-bottom: 0;
}
