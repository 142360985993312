@use '../../../styles/utils' as *;

.propertyCard {
  display: flex;
  position: relative;

  @include breakpoint-md {
    flex-direction: column;
  }
}

.propertyCard.propertyVariant {
  flex-direction: column;
  width: 100%;

  @include breakpoint-md {
    width: rem(308);
  }

  @include breakpoint-lg {
    width: rem(282);
  }
}

.linkWrapper {
  width: 100%;

  @include breakpoint-md {
    width: auto;
  }
}

.propertyImageWrapper {
  background-color: rgba($color-light-slate, 40%);
  height: rem(128);
  min-width: rem(128);
  position: relative;
  width: rem(128);

  @include breakpoint-md {
    width: auto;
  }

  * {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
}

.propertyVariant .propertyImageWrapper {
  height: rem(144);
  width: auto;
}

.propertyContentWrapper {
  display: flex;
  flex-direction: column;
  margin-left: rem(16);

  @include breakpoint-md {
    margin-left: 0;
    padding: rem(14) 0;
  }

  p {
    color: $color-slate;
  }
}

.propertyVariant .propertyContentWrapper {
  margin-left: 0;
  padding: rem(14) 0;
}

.propertyType {
  font-size: rem(14);
  font-weight: 600;
  line-height: rem(20);
  margin-bottom: rem(10);
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: rem(4);

  @include themify($themes) {
    text-decoration-color: themed('backgroundColor');
  }
}

.propertyTitle {
  font-size: rem(16);
  font-weight: bold;
  line-height: rem(24);
}

.propertyPriceRange {
  font-size: rem(16);
  line-height: rem(24);
  margin-bottom: rem(16);
}

.propertyStatsWrapper {
  align-items: center;
  border-top: 1px solid rgba($color-slate, 24%);
  display: flex;
  padding-top: rem(16);
}

.propertyStat {
  align-items: center;
  display: flex;
  font-weight: 600;
  margin-right: rem(18);

  svg {
    margin-right: rem(6);
    max-height: rem(20);
  }
}

.statusBadge {
  left: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}
