@use '../../styles/utils' as *;

.pill {
  @include base-paragraph-regular;
  @include font-size(14 20);

  font-weight: 600;
  text-decoration: underline;
  text-decoration-thickness: rem(2) !important;
  text-underline-offset: rem(5);
}

@include color-classes('text-decoration-color');
