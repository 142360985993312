@use '../../styles/utils' as *;

.buttonIcon span {
  margin: 0 0.375rem 0 0.625rem;
  width: 0.75rem;
}

.buttonContents {
  align-items: center;
  display: flex;
}
