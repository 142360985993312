@use '../../../styles/utils' as *;

.mapCardContainer {
  background-color: $color-white;
  display: flex;
  flex-direction: column;
  max-width: rem(324);
  padding: 0;
  width: 100%;
}

.mapCardTitle {
  align-items: center;
  background-color: $color-lime;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: rem(28) rem(16);
}

.orangeBackground {
  background-color: $color-orange !important;
}

.blueBackground {
  background-color: $color-blue !important;
}

.lilacBackground {
  background-color: $color-lilac !important;
}

.mapCardCta button {
  justify-content: center;
  margin-top: auto;
  width: 100%;
}

.mapCardHero {
  width: 100%;
}

.mapCardHero img {
  height: rem(150);
  object-fit: cover;
  width: 100%;
}

.mapCardCta {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: rem(32);
}

.mapCardCta p {
  margin-bottom: rem(5);
}

.mapCardCta h4 {
  margin-bottom: rem(10);
}

.mapCardCta span {
  margin-top: auto;
}

.mapCardCta a {
  width: auto;
}
