@use '../../styles/utils' as *;

.statusBadgeContainer {
  color: $color-white;
  font-size: rem(14);
  font-weight: bold;
  padding: rem(12) rem(16);
  width: auto;
}

.statusBadgeContainer.small {
  font-size: 12px;
  line-height: 16px;
  padding: rem(4) rem(8);
}

.redBackground {
  background-color: $color-error;
}

.yellowBackground {
  background-color: $color-yellow;
  color: $color-slate;
}
