@use '../../styles/utils/' as *;

.contactCardListContainer {
  display: flex;
  flex-direction: column;
  gap: rem(24);
  margin: rem(24) 0 rem(64);
  width: auto;

  @include breakpoint-md {
    flex-direction: row;
    margin: 0 0 rem(64);
  }

  @include breakpoint-lg {
    flex-direction: column;
  }
}

.contactCardListTeamBlock {
  width: auto;
}

.contactCardListWrapper {
  display: flex;
  flex-direction: column;
  gap: rem(25);
  margin-top: rem(25);
}

.contactCardListCards {
  display: flex;
  flex-direction: column;
  gap: rem(25);
  margin-top: rem(25);
}

.contactCardListCards.propertyVariant {
  margin-top: 0;
}

.contactCardListAddressBlock {
  margin-top: rem(12);

  h4 {
    margin-bottom: rem(24);
  }

  img {
    max-height: rem(65);
  }
}
